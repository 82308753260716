<template lang="pug">
.drop-down(:class="{'active': show}" :ref="generateRef('dropDown')")
  label {{ label }}
  button.drop-down-toggle(
  :ref="generateRef('toggle')"
  :disabled="disabled"
  :readonly="suitable"
  ) {{ selectedValues }}
  .drop-down-menu(v-show="show" :ref="generateRef('menu')" data-menu)
    .drop-down-menu-item(
      :ref="generateRef('menuItem')"
      v-for="(option, index) in options"
      :key="option.id || option.key"
      :class="{active: isActive(option[optionValue])}"
      @click="update(option[optionValue] || option)"
    )
      .checkbox
      span {{ typeof option.value !== 'undefined' ? option.value : option[optionLabel] || option }}
</template>

<script>
  export default {
    data() {
      return {
        show: false,
      }
    },
    props: {
      optionLabel: {},
      optionValue: {},
      options: {},
      label: {},
      multiple: {
        default: false
      },
      disabled: {
        default: false
      },
      value: {
        default: () => []
      },
    },
    model: {
      prop: 'value',
      event: 'click'
    },
    // watch: {
    //   value: function (value) {
    //     this.$emit('click', value)
    //   }
    // },
    computed: {
      suitable() {
        return this.options !== [] && this.options && this.options !== 'undefined'
      },
      selectedValues() {
        //if it's not Array, turn it to from the Object to the Array
        const options = Array.isArray(this.options) ? this.options : Object.values(this.options);

        let value = options
            .filter(option => {
              return Array.isArray(this.value) ? 
                this.value.includes(option.id) || this.value.includes(option.key) :
                this.value == option.id || this.value == option.key
            })
            .map(option => option[this.optionLabel])

        return value.length <= 1 ? value[0] || this.$t('dropDown.all') : this.countSelected
      },
      countSelected() {
        return `${this.value.length} ${this.$t('dropDown.of')} ${this.options.length} ${this.$t('dropDown.choice')}`
      },
    },
    methods: {
      generateRef(ref) {
        return `${ref}_${this._uid}`
      },
      update(value) {
        if (this.multiple) {
          let index = this.value.indexOf(value)
          if (index !== -1) {
            this.value.splice(index, 1)
          } else {
            this.value.push(value)
          }
          this.$emit('click', this.value)
        } else {
          this.$emit('click', value)
        }

        if (window.matchMedia("(max-width: 767px)").matches) {
          this.showToggle();
        }
      },
      showToggle() {
        this.show = !this.show
      },
      isActive(value) {
        if (this.multiple) {
          return this.value.includes(value)
        } else {
          return Array.isArray(this.value) ? this.value.includes(value) : this.value === value 
        }
      },
      close(e) {
        let target = e.target,
            attrs = target.getAttributeNames(),
            toggle = this.$refs[this.generateRef('toggle')],
            menuItems = this.$refs[this.generateRef('menuItem')];

        if (this.show) {
          if (attrs.includes('data-menu')) return;
          
          const menuHasTarget = menuItems.includes(target)

          if (!menuHasTarget || target === toggle) {
            this.show = false
          }
        } else {
          if (target === toggle) {
            this.showToggle()
          }
        }
      }
    },
    mounted() {
      document.addEventListener('mousedown', this.close)
    }
  }
</script>

<style lang="sass">
  @import '@/style/components/DropDown.sass'
</style>
